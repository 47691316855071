


















































































































import {Component, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import {SupportedTokenCollection} from '@/model/collection/SupportedTokenCollection'
import {ListSupportedTokenSchema} from '@/schema/resource/SupportedToken/ListSupportedTokenSchema'
import {SupportedToken} from '@/model/resource/SupportedToken'

@Component
export default class ListSupportedTokenView extends Mixins(MixinRouteMatch) {
  schema = new ListSupportedTokenSchema()
  collection = new SupportedTokenCollection()

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    this.collection.setOrderBy('order')
    await this.populate()
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: SupportedToken) {
    this.$nav.pushByName('editSupportedToken', item.$id)
  }

  async removeItem(item: SupportedToken) {
    await this.$dialog.remove(item)
    await item.removeSupportedToken()
    await this.collection.queryAsPage()
  }

  async changeOrder(item: SupportedToken, increment: number) {
    if (item.order === null) item.order = 0
    item.order += increment
    await item.changeOrder()
    this.$toast.success('system.success.persist')
    await this.populate()
  }

  async populate() {
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }
}
