/**
 * SupportedTokenCollection
 * @author Lopes
 */
import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {SupportedToken} from '@/model/resource/SupportedToken'

@HttpExclude()
export class SupportedTokenCollection extends ExpansibleCollection<
  SupportedToken
> {
  constructor() {
    super(SupportedToken)
  }

  resource?: ISupportedTokenCollectionResourcesHolder

  @RequestExpose() minOrder: number | null = null
  @RequestExpose() maxOrder: number | null = null

  queryAsPage() {
    return this.listSupportedToken()
  }

  async listSupportedToken() {
    return await Request.get(`/client/supportedToken`, {params: this.params})
      .name('listSupportedToken')
      .as(this)
      .getResponse()
  }
}

export interface ISupportedTokenCollectionResourcesHolder {}
